import React from 'react'

export const NativeChatsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="37"
      viewBox="0 0 34 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Native Icon Gradients">
        <path
          id="Vector"
          d="M15.7525 7.39648H8.08645C6.06222 7.39648 4.12091 8.21916 2.68956 9.68354C1.25822 11.1479 0.454102 13.134 0.454102 15.205L0.454102 25.2657C0.454102 26.2911 0.651518 27.3065 1.03508 28.2539C1.41864 29.2012 1.98083 30.062 2.68956 30.7871C3.39829 31.5122 4.23968 32.0874 5.16567 32.4798C6.09167 32.8722 7.08415 33.0742 8.08645 33.0742H23.4129V16.0753V15.8063V15.2314C23.4123 13.1533 22.605 11.1604 21.1685 9.69115C19.7319 8.22188 17.7838 7.39648 15.7525 7.39648Z"
          fill="url(#paint0_linear_5449_5979)"
        />
        <path
          id="Vector_2"
          d="M25.2572 7.39648H15.7556C13.6409 7.39743 11.6131 8.25026 10.1181 9.76746C8.62304 11.2847 7.7832 13.342 7.7832 15.4872V28.5081H25.2572C26.1052 28.5082 26.945 28.3388 27.7284 28.0096C28.5118 27.6804 29.2236 27.1978 29.8231 26.5893C30.4226 25.9809 30.898 25.2586 31.2221 24.4637C31.5462 23.6688 31.7127 22.8169 31.7121 21.9567V13.9443C31.7121 12.2077 31.0321 10.5422 29.8215 9.3143C28.611 8.08634 26.9692 7.39648 25.2572 7.39648Z"
          fill="url(#paint1_linear_5449_5979)"
        />
        <path
          id="Vector_3"
          d="M15.5961 7.39648C13.5237 7.39743 11.5364 8.25026 10.0713 9.76746C8.60624 11.2847 7.7832 13.342 7.7832 15.4872V28.5081H23.4125V15.4872C23.4125 13.3414 22.589 11.2835 21.1231 9.7662C19.6573 8.2489 17.6691 7.39648 15.5961 7.39648Z"
          fill="url(#paint2_linear_5449_5979)"
        />
        <path
          id="Vector_4"
          d="M33.5445 32.3819C33.5447 31.9931 33.4636 31.6081 33.306 31.2489C33.1483 30.8896 32.9172 30.5632 32.6258 30.2882C32.3344 30.0133 31.9883 29.7951 31.6075 29.6463C31.2267 29.4975 30.8185 29.4209 30.4063 29.4209H10.9191C10.0874 29.4215 9.28994 29.7334 8.70195 30.2882C8.11397 30.843 7.78352 31.5952 7.7832 32.3797V36.7267H28.8858H30.4087C31.2397 36.7261 32.0366 36.4146 32.6245 35.8606C33.2124 35.3065 33.5433 34.5551 33.5445 33.7713V32.3819Z"
          fill="url(#paint3_linear_5449_5979)"
        />
        <path
          id="Vector_5"
          d="M28.9639 2.34481V3.31617C28.9639 3.91396 28.6792 4.48728 28.1725 4.90998C27.6658 5.33269 26.9786 5.57016 26.262 5.57016L7.78353 5.57016V2.34481C7.78353 1.74701 8.06819 1.1737 8.57489 0.750998C9.08159 0.328293 9.76883 0.0908203 10.4854 0.0908203L26.262 0.0908203C26.9786 0.0908203 27.6658 0.328293 28.1725 0.750998C28.6792 1.1737 28.9639 1.74701 28.9639 2.34481Z"
          fill="url(#paint4_linear_5449_5979)"
        />
        <path
          id="Vector_6"
          d="M21.9655 33.0738H23.4125V29.4209H10.8171C10.0124 29.4215 9.24094 29.7343 8.67208 30.2906C8.10321 30.8469 7.78351 31.6012 7.7832 32.3878V33.0738H21.9655Z"
          fill="#004E35"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5449_5979"
          x1="11.9341"
          y1="24.6173"
          x2="11.9341"
          y2="50.3904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0058E5" />
          <stop offset="1" stopColor="#003BA4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5449_5979"
          x1="19.7453"
          y1="7.05823"
          x2="19.7453"
          y2="29.1205"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#FACA9D" />
          <stop offset="0.99" stopColor="#F4A061" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5449_5979"
          x1="15.5961"
          y1="7.26118"
          x2="15.5961"
          y2="28.4831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003BA4" />
          <stop offset="1" stopColor="#002861" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5449_5979"
          x1="20.6621"
          y1="32.478"
          x2="20.6621"
          y2="43.9553"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007443" />
          <stop offset="1" stopColor="#013413" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5449_5979"
          x1="18.3719"
          y1="0.0503058"
          x2="18.3719"
          y2="7.91901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E59E38" />
          <stop offset="1" stopColor="#BF7408" />
        </linearGradient>
      </defs>
    </svg>
  )
}
