import React from 'react'

export const LogoNativeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="123" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M40.785 8.64c-.188-4.737-4.308-8.63-8.93-8.446h-7.968V7.61h5.318c3.365-.008 3.814 1.445 3.96 2.77h1.703v13.622h5.926V11.487c.01-.066-.009-2.846-.009-2.846Z"
        fill="#007443"
      />
      <path
        d="m33.174 11.49-.008-1.099h-4.797a7.047 7.047 0 0 0-2.683.49 6.946 6.946 0 0 0-2.281 1.468 6.798 6.798 0 0 0-1.528 2.22 6.694 6.694 0 0 0 0 5.25 6.797 6.797 0 0 0 1.528 2.219 6.944 6.944 0 0 0 2.281 1.468 7.045 7.045 0 0 0 2.683.49h4.808l-.003-12.506Z"
        fill="#007443"
      />
      <path
        d="m33.166 10.385.008 1.099v12.52h1.694v-13.62h-1.702Z"
        fill="#007443"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path d="m33.166 10.385.008 1.099v12.52h1.694v-13.62h-1.702Z" fill="#013413" />
      <path d="M80.131 23.998h7.527L93.13.198H74.577l5.554 23.8Z" fill="#E59E38" />
      <path
        d="M9.366.012a9.456 9.456 0 0 0-6.623 2.692A9.107 9.107 0 0 0 0 9.204v14.801h18.731v-14.8a9.107 9.107 0 0 0-2.743-6.5A9.455 9.455 0 0 0 9.366.011Z"
        fill="#003BA4"
      />
      <path
        d="M71.612 5.965V.197H64a.008.008 0 0 0-.005.003.008.008 0 0 0-.002.005v7.403-1.643h7.618ZM71.612 7.617H64v16.385h7.618V5.969l-.007 1.648Z"
        fill="#DB532A"
      />
      <path
        d="M66.7 7.616h4.912V5.963h-7.615V7.61l2.703.005Z"
        fill="#DB532A"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path d="M66.7 7.616h4.912V5.963h-7.615V7.61l2.703.005Z" fill="#BF3110" />
      <path
        d="M94.308 12.433V13.5h.003l-.003-1.653v.585Z"
        fill="#FACA9D"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M104.976.111c-6.008-.887-10.668 3.641-10.668 9.923v1.813h7.632c0 .473-.011 1.213.032 1.653h10.903V9.119c0-2.18-.798-4.29-2.247-5.942a9.334 9.334 0 0 0-5.652-3.066ZM105.823 16.518H104.701a5.765 5.765 0 0 1-.71-.114 2.84 2.84 0 0 1-.631-.223 2.136 2.136 0 0 1-.199-.115c-.605-.39-1.072-1.122-1.197-2.562h-7.653v1.718a8.642 8.642 0 0 0 2.488 6.098 8.968 8.968 0 0 0 6.117 2.667h8.294v-7.468l-5.387-.001Z"
        fill="#FACA9D"
      />
      <path
        d="M101.939 12.66v-.808h-7.624v1.653h7.655c-.031-.263-.031-.54-.031-.844Z"
        fill="#FACA9D"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M101.939 12.66v-.808h-7.624v1.653h7.655c-.031-.263-.031-.54-.031-.844Z"
        fill="#F4A061"
      />
      <path
        d="M43.574.205v7.403s.007.01.016.01h3.893V5.963h7.62v1.653h5.6V.201H43.59c-.008-.004-.015 0-.015.004Z"
        fill="#931C3B"
      />
      <path
        d="M54.707 7.617h-7.224v5.084l.008 2.846a8.663 8.663 0 0 0 2.72 6.002 8.99 8.99 0 0 0 6.21 2.445h2.627v-7.44l.018-.011c-3.37 0-3.815-1.419-3.96-2.746l-.015-1.099v-5.08h-.384Z"
        fill="#931C3B"
      />
      <path
        d="m47.484 5.963-.001 1.653h7.619l.002-1.653h-7.62Z"
        fill="#931C3B"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path d="m47.484 5.963-.001 1.653h7.619l.002-1.653h-7.62Z" fill="#6D0018" />
      <path
        d="M119.556 17.925a3.178 3.178 0 0 0-1.804.183c-.57.235-1.056.63-1.396 1.137a3.025 3.025 0 0 0 .017 3.407c.346.503.836.893 1.408 1.123.573.229 1.201.286 1.807.165a3.144 3.144 0 0 0 1.595-.848 3.05 3.05 0 0 0 .843-2.771 3.03 3.03 0 0 0-.472-1.104 3.134 3.134 0 0 0-1.998-1.291Z"
        fill="#EEFF34"
      />
    </svg>
  )
}
