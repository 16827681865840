import React from 'react'

export const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.896 4.348C6.38 4.425 5 5.668 5 7.334v9.332c0 2.22 2.453 3.693 4.412 2.648l8.75-4.668c2.068-1.103 2.068-4.19 0-5.292l-8.75-4.668a2.887 2.887 0 0 0-1.516-.338ZM7.471 6.45c.28-.168.647-.188 1 0l8.75 4.666c.755.403.755 1.363 0 1.766l-8.75 4.666C7.765 17.925 7 17.465 7 16.666V7.334c0-.4.191-.715.47-.883Z" />
    </svg>
  )
}
